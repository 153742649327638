<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 col-md-12 ml-auto mr-auto">
            <h1>Migrate a WordPress site into Docker</h1>
            <p>When I was trying to get a WordPress instance Dockerized, there was an overwhelming amount of information, all with slightly different approaches that were almost paralyzing.  I also did not find a whole lot of information around WordPress. So, I wanted to share the steps that helped me, along with a <strong><a href="yml/docker-compose.yml" target="_blank">sample <pre>docker-compose.yml</pre> file</a></strong>. I hope it will help others save a few hours!</p><br />

            <h2>Step 0: Establish a starting point</h2>
            <p>Review <a href="https://hub.docker.com/_/wordpress" target="_blank">Docker's WordPress page</a> for general syntax and reference the Wordpress Docker image in your <pre>docker-compose.yml</pre> file.</p><br />

            <h2>Step 1: Download &amp; login</h2>
            <p>Download Docker, set up an account, and login via the terminal with <pre>$ docker login</pre>.</p><br />
            <h2>Step 2: Backup WordPress database</h2>
            <p>In your terminal, make a directory where you want your dump file to live (example: <pre>$ mkdir wordpress-bkup</pre>).  Install <pre>mysqldump</pre> via brew or other installer for your OS: <pre>$ brew install mysqldump</pre>. Use <pre>mysqldump</pre> to create a database backup of your WordPress database as a local <pre>.sql</pre> file via <pre>$ mysqldump --user={USER} --password={PW} --host={HOST} cms > docker-data/all.sql</pre>. Your package.json file should have the credentials for the environment variables.</p>
            <br />
            
            <h2>Step 3: Update the Migrate SQL</h2>
            <p>Update the URLs containing your domain in order to run in your desired location.  In my case, since I wanted to run the WordPress instance locally, I updated the URLs to http://127.0.0.1:8080 in the <pre>migrate.sql</pre> file.  You may need to add or remove tables based on your environment.</p>
            <code>
            UPDATE wp_options SET option_value = replace(option_value, 'https://domain.com', 'http://127.0.0.1:8080') WHERE option_name = 'home' OR option_name = 'siteurl';<br />
            UPDATE wp_posts SET guid = replace(guid, 'https://domain.com','http://127.0.0.1:8080');<br />
            UPDATE wp_posts SET post_content = replace(post_content, 'https://domain.com', 'http://127.0.0.1:8080');<br />
            UPDATE wp_postmeta SET meta_value = replace(meta_value,'https://domain.com','http://127.0.0.1:8080');<br />
            UPDATE wp_blogs SET domain = replace(domain,'https://domain.com','http://127.0.0.1:8080');<br />
            UPDATE wp_site SET domain = replace(domain,'https://domain.com','http://127.0.0.1:8080');
            </code>

            <h2>Step 4: Restore the database</h2>
            <p>Add a <pre>volumes</pre> section in your <pre>docker-compose.yml</pre> file and reference the database dump file and <pre>migrate.sql</pre> file to include your database in the image. For more information, you can reference the <a href="https://hub.docker.com/_/mysql" target="_blank">MySQL DockerHub doc</a> (see "initializing a fresh instance" section).  Example below:</p>
            <code>volumes:<br />
              - ./directory_of_dump_file/dump_filename.sql:/docker-entrypoint-initdb.d/dump_filename.sql<br />
              - ./directory_of_dump_file/migrate.sql:/docker-entrypoint-initdb.d/migrate.sql</code>
            

            <h2>Step 5: Local WordPress database credentials</h2>
            <p>In your <pre>docker-compose.yml</pre> file, use your generic credentials such as:</p>
            <code>WORDPRESS_DB_USER: cms<br />
            WORDPRESS_DB_PASSWORD: password<br />
            WORDPRESS_DB_NAME: cms</code>
            

            <h2>Step 6: Build and run</h2>
            <p>Build your image and run the container via <pre>$ docker-compose up --build</pre>. </p>
            <p>By the way, no need to worry when you see the following in the docker-compose logs: <pre>PHP Warning:  mysqli::__construct(): (HY000/2002): Connection refused in Standard input code on line 2</pre><br />
            <pre>MySQL Connection Error: (2002) Connection refused</pre>.  This is not a bug and is just the WordPress service trying to access the database before it is ready to accept requests. It will retry multiple times and eventually your database will accept requests.</p><br />

            <h2>Step 7: Verify in browser</h2>
            <p>When the build is done (i.e., when docker-compose logs stop generating), navigate to http://127.0.0.1:8080/wp-admin/ and use your WordPress credentials to login from the WP environment/database you migrated over.</p><br />

            <h2>Step 8: Lock down image</h2>
            <p>When you run a build, it runs every single file in your directory.  Include a <pre>.dockerignore</pre> file to omit any files that may contain sensitive information and other non-essential files to the build (such as README.md, .DS_Store, .bin, log files, .aws, etc.).  This will help reduce the size of your image and improve build times of your application.  Adding <pre>**/.git</pre> to the <pre>.dockerignore</pre> file ignores all .git folders so the whole git history is not included.  You can also include <pre>node_modules</pre> if you are using Node. The following are items I generally include in a <pre>.dockerignore</pre> file.</p>
            <code>.gitignore<br />
            Dockerfile<br />
            README.md<br />
            node_modules<br />
            **/.git</code>

            <h2>Exit</h2>
            <p>To shut down, type <pre>$ docker-compose down</pre>.</p><br />

            <h3>Heads up</h3>
            <p>Sometimes you might run into weird errors.  Try shutting down and restarting to see if that will do the trick via <pre>$ docker-compose down</pre> and then <pre>$ docker-compose up --build</pre>.</p><br />

            <h3>Optimizing your Dockerfile</h3>
            <p>Optimizing your Dockerfile can go a long way to reduce build times of your application both locally and when deploying. Below are a few resources with some best practices to use when optimizing Dockerfiles.</p>
            <p><a href="https://docs.docker.com/develop/develop-images/dockerfile_best-practices/" target="_blank">https://docs.docker.com/develop/develop-images/dockerfile_best-practices/</a></p>
            <p><a href="https://dev.to/azure/improve-your-dockerfile-best-practices-5ll" target="_blank">https://dev.to/azure/improve-your-dockerfile-best-practices-5ll</a></p>
            <p><a href="https://hackernoon.com/do-not-ignore-dockerignore-47f5fb67b448" target="_blank">https://hackernoon.com/do-not-ignore-dockerignore-47f5fb67b448</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'jekyll',
  bodyClass: 'landing-page'
};
</script>
<style></style>
