<template>
  <div class="section section-examples" data-background-color="black">
    <div class="container">
      <div class="row justify-content-md-center">
        <h2>Portfolio</h2> 
      </div>
      <div class="col">
        <router-link to="/docker">
          <img src="img/projects/docker-compose.jpg" alt="Docker-compose script an code editor" class="img-raised" />
        </router-link>
        <router-link
          to="/docker"
          class="btn btn-simple btn-primary btn-round">Migrating WordPress into Docker
        </router-link>
      </div>
      <div class="col">
        <router-link to="/jekyll">
          <img src="img/projects/jekyll.png" alt="Jeykll script in an code editor" class="img-raised" />
        </router-link>
        <router-link
          to="/jekyll"
          class="btn btn-simple btn-primary btn-round">Adding data on microsites via Jekyll &amp; .getJSON
        </router-link>
      </div>
      <div class="col">
        <router-link to="/regex">
          <img src="img/projects/regex-pwd.jpg" alt="Code in an code editor" class="img-raised" />
        </router-link>
        <router-link
          to="/regex"
          class="btn btn-simple btn-primary btn-round">Client-side password validation w/ regex &amp; .validator.addmethod
        </router-link>
      </div>
      <div class="col">
        <router-link to="/skrollr">
          <img src="img/projects/mwb-skrollr-sm.png" alt="skroller.js and YAMM project" class="img-raised" />
        </router-link>
        <router-link
          to="/skrollr"
          class="btn btn-simple btn-primary btn-round">Skrollr.js &amp; YAMM
        </router-link>
      </div>
      <div class="col">
        <router-link to="/landingpage">
          <img src="img/projects/bdc-dg3-01-2016sm.jpg" alt="Hand holding a mobile device with paper in trash can" class="img-raised" />
        </router-link>
        <router-link
          to="/landingpage"
          class="btn btn-simple btn-primary btn-round">Landing page
        </router-link>
      </div>
      <div class="col">
        <router-link to="/fullhero">
          <img src="img/projects/bdc-accountants-hero-sm-7-2015.jpg" alt="Person sitting at laptop" class="img-raised" />
        </router-link>
        <router-link
          to="/fullhero"
          class="btn btn-simple btn-primary btn-round">Full viewport hero
        </router-link>
      </div>
      <div class="col">
        <router-link to="/modal">
          <img src="img/projects/bdc_modal_sm_12-2015.jpg" alt="Business woman wearing a blue shirt and black skirt" class="img-raised" />
        </router-link>
        <router-link
          to="/modal"
          class="btn btn-simple btn-primary btn-round">Modal
        </router-link>
      </div>
      <div class="col">
        <router-link to="/https">
          <img src="img/projects/bdc-https-12-2015sm.gif" alt="Gray letters spelling https" class="img-raised" />
        </router-link>
        <router-link
          to="/https"
          class="btn btn-simple btn-primary btn-round">Sitewide https
        </router-link>
      </div>
      <div class="col">
        <router-link to="/parallax">
          <img src="img/projects/bdc_parallax_scrolling_sm-7-2015.jpg" alt="Gold lock with mobile devices in the background" class="img-raised" />
        </router-link>
        <router-link
          to="/parallax"
          class="btn btn-simple btn-primary btn-round">Parallax scrolling
        </router-link>
      </div>
      <div class="col">
        <router-link to="/nth-attempts">
          <img src="img/projects/bdc-signup_recaptcha_sm-05-2015.jpg" alt="Sign up form on the left and testimonials on the right" class="img-raised" />
        </router-link>
        <router-link
          to="/nth-attempts"
          class="btn btn-simple btn-primary btn-round">reCAPTCHA - Display After n Attempts
        </router-link>
      </div>
      <div class="col">
        <router-link to="/app-test">
          <img src="img/projects/br_dashboard-1-2016sm.jpg" alt="Dashboard of the Bill.com app in 2016" class="img-raised" />
        </router-link>
        <router-link
          to="/app-test"
          class="btn btn-simple btn-primary btn-round">A/B testing in the app
        </router-link>
      </div>
      <div class="col">
        <router-link to="/bdc-home">
          <img src="img/projects/bdc_home_09-2014s.png" alt="Person holding a tablet device" class="img-raised" />
        </router-link>
        <router-link
          to="/bdc-home"
          class="btn btn-simple btn-primary btn-round">Bill.com marketing website
        </router-link>
      </div>
      <div class="col">
        <router-link to="/developer-bdc">
          <img src="img/projects/bdc-devportal-sm-4-2015.jpg" alt="Homepage of Bill.com's Developer Portal in 2014" class="img-raised" />
        </router-link>
        <router-link
          to="/developer-bdc"
          class="btn btn-simple btn-primary btn-round">developer.bill.com
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
