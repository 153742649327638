<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12 ml-auto mr-auto">
            <h1>Sitewide https</h1>
            <p><em>Lead Developer (Bill.com)</em></p>
            <p>Conducted due diligence on HTTPS migration best practices, documented checklist of tasks, updated all internal URLs and external plugins to ensure they are HTTPS compliant, implemented HTTP to HTTPS 301 redirects, performed HTTPS migration on test server, verified errors in Qualys SSL Labs, and resolved issues.</p><br />
              
              <img src="img/projects/bdc-https-12-2015.gif" alt="Gray letters spelling https" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'https',
  bodyClass: 'landing-page'
};
</script>
<style></style>
