<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12 ml-auto mr-auto">
            <h1>developer.bill.com</h1>
            <p><em>Lead Developer (Bill.com)</em></p>
            
            <p>Sub-site of the marketing website that provides API documentation to help other developers get started with integrating with Bill.com. Lead development of user interface and eventually became application lead and provided maintenance including re-skinned look and feel a year later.</p>
            <p>Technologies: Drupal 7, HTML, CSS, PHP, JavaScript/jQuery, PhotoShop, MySQL</p><br />
              <img src="img/projects/bdc-devportal-4-2015.jpg" alt="Homepage of Bill.com's Developer Portal in 2014" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'developerbdc',
  bodyClass: 'landing-page'
};
</script>
<style></style>
