<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12 ml-auto mr-auto">
            <h1>100% Viewport Hero</h1>
            <p><em>Lead Developer for Accountant Program Redesign (Bill.com)</em></p>
            
            <p>I was the lead developer for redesigning the Accountant Program section. The hero on the landing page takes up the full screen on page load; the divs containing the H1 wraps to 2 lines when the browser is vertically taller while the accounting logos wraps to two lines in reduced height. Content functions normally upon scroll.</p>
            <p>The divs wrap down when a browser window is scaled down to size.</p>
              <h4><a href="http://www.bootply.com/UJ94ZAHpkb" target="_blank">See Demo</a></h4><br />
              <a href="http://www.bootply.com/UJ94ZAHpkb" target="_blank"><img src="img/projects/bdc-accountants-hero-7-2015.jpg" alt="Person sitting and working in front of laptop" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'fullhero',
  bodyClass: 'landing-page'
};
</script>
<style></style>
