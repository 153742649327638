<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12 ml-auto mr-auto">
            <h1>Skrollr.js &amp; YAMM</h1>
            <p>I wanted to try out skrollr.js and YAMM (a responsive Bootstrap mega menu) and tried it out on a Malwarebytes webpage. I had a lot of fun dev-ing this! (-:<p>
              <h4><a href="https://winniekwok.com/skrollr-ex.html">See Demo</a></h4><br />
              <a href="https://winniekwok.com/skrollr-ex.html"><img src="img/projects/mwb-skrollr.png" alt="skroller.js and YAMM project" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'skrollr',
  bodyClass: 'landing-page'
};
</script>
<style></style>
