<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12 ml-auto mr-auto">
            <h1>Bill.com Marketing Website</h1>
            <p><em>Web Developer and Site Administrator (Bill.com)</em></p>
            
            <p>Provide daily maintenance and technical support that includes improving functionality, SEO, accessibility, scalability, and maintainability. Collaborate with stakeholders to develop, A/B test, troubleshoot, and deploy new/updated responsive features and applications.</p>
            <p>Technologies: Drupal 7, Bootstrap 3, HTML, CSS/CSS3, LESS, PHP, JavaScript/jQuery, PhotoShop, MySQL</p><br />
              <img src="img/projects/bdc_home_09-2014.png" alt="Person holding a tablet device" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bdchome',
  bodyClass: 'landing-page'
};
</script>
<style></style>
