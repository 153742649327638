<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12 ml-auto mr-auto">
            <h1>A/B testing in app.bill.com</h1>
            <p><em>Lead Developer (Bill.com)</em></p>
            
            <p>I developed an A/B test in the app. Functionality was ultimately to remove an existing div, place a new div before an existing div, and appending another div. The test doubled the amount of good opportunities. Script used was a combination of .replace(); .before(); and .appendTo();</p><br />
            
              <img src="img/projects/br_dashboard-1-2016.jpg" alt="Dashboard of the Bill.com app in 2016" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'apptest',
  bodyClass: 'landing-page'
};
</script>
<style></style>
