<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12 ml-auto mr-auto">
            <h1>Landing Page</h1>
            <p><em>Lead Developer (Bill.com)</em></p>
            <p>A demand generation landing page that I developed. It is responsive for tablet and mobile phone.</p>
              <h4><a href="http://cashflow.bill.com/SMB3.html" target="_blank">See Landing Page</a></h4><br />
              <a href="http://cashflow.bill.com/SMB3.html" target="_blank"><img src="img/projects/bdc-dg3-01-2016.jpg" alt="Hand holding a mobile device with paper in trash can" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'landingpage',
  bodyClass: 'landing-page'
};
</script>
<style></style>
