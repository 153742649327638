<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12 ml-auto mr-auto">
            <h1>Modal Example</h1>
            <p><em>Lead Developer (Bill.com)</em></p>
            
            <p>I developed a modal as an A/B test for the Bill.com homepage to help improve segmentation of the site's audience. The close button appears few seconds after main modal loads. Modal will not load again after close button is clicked. I developed the image as part of a div and sandwiched between the background color and the text. Mobile experience developed as well.</p>
            
              <h4><a href="http://www.bootply.com/CmBKFITUiv" target="_blank">See Demo</a></h4><br />
              <a href="http://www.bootply.com/CmBKFITUiv" target="_blank"><img src="img/projects/bdc_modal_12-2015.jpg" alt="Business woman wearing a blue dress shirt and black skirt" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal',
  bodyClass: 'landing-page'
};
</script>
<style></style>
