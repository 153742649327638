<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12 ml-auto mr-auto">
            <h1>reCaptcha - Display After N Attempts</h1>
            <p><em>Lead Developer (Bill.com)</em></p>
            
            <p>Displayed Google reCAPTCHA widget after n signups within n seconds. Developed experience when JavaScript is either enabled or disabled. When js disabled, PHP form post method is used. When js is enabled, used jQuery.getJSON/XDR (XDR used for IE9).</p><br />
            
              <img src="img/projects/bdc-signup_recaptcha_05-2015.jpg" alt="Sign up form on the left and testimonials on the right" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nattempts',
  bodyClass: 'landing-page'
};
</script>
<style></style>
