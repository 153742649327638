<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12 ml-auto mr-auto">
            <h1>Parallax Scrolling</h1>
            <p><em>Lead Developer for Accountant Program Redesign (Bill.com)</em></p>
            
            <p>I was the lead developer for redesigning the Accountant Program section and one of the sections needed Parallax scrolling.</p>
            
              <h4><a href="http://www.bootply.com/COWqHlnymA" target="_blank">See Demo</a></h4><br />
              <a href="http://www.bootply.com/COWqHlnymA" target="_blank"><img src="img/projects/bdc_parallax_scrolling_7-2015.jpg" alt="Gold lock with mobile devices in the background" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'parallax',
  bodyClass: 'landing-page'
};
</script>
<style></style>
